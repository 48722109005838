import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatLinkString } from "../../../../../components/utils/functions";

const CertificationCard = (props) => {
  const { certification, handleChange, handleDelete } = props;

  const { t } = useTranslation();
  return (
    <div className="item-timeline">
      <div className="timeline-year">
        {" "}
        <span>
          {certification?.date}
        </span>
      </div>
      <div className="timeline-info">
        <h5 className="color-brand-1 mb-20">{certification?.designation}</h5>
        <p className="color-text-paragraph-2 mb-15">
          {t("textDescription")}: <span dangerouslySetInnerHTML={{__html: certification?.description}}></span> <br/>
          {t("textJoinedFile")}: <Link to={formatLinkString(certification.file)}>{String(certification.file).split('/').reverse()[0]}</Link> <br/>
        </p>
      </div>
      <div className="timeline-actions">
        {" "}
        <Link to={"#"} onClick={(e) => handleChange(certification)} className="btn btn-editor position-inherit m-auto"></Link>
        <Link to="#" onClick={(e) => handleDelete(certification)} className="btn btn-remove m-auto"></Link>
      </div>
    </div>
  );
};

export default CertificationCard;
