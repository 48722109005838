import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  login_request: false,
  login_sucess: false,
  login_failed: false,
  auth: undefined,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.login_request = true;
      state.login_sucess = false;
      state.login_failed = false;
    },
    loginSuccess: (state, action) => {
        state.login_request = false;
        state.login_sucess = true;
        state.login_failed = false;
        state.auth = action.payload
    },
    loginFailed: (state) => {
        state.login_request = false;
        state.login_sucess = false;
        state.login_failed = true;
    },
    updateUserData: (state, action) => {
      
      if(state.auth){
        state.auth.user = action.payload;
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { loginRequest, loginSuccess, loginFailed, updateUserData } = authSlice.actions

export default authSlice.reducer