export const DEFAULT_PAGE_COUNT = 25;

export const DEFAULT_IMAGE = "/favicon.ico";
export const DEFAULT_PROFILE = "/assets/imgs/avatar/avatar.png";

export const APPLICATION_STEPS = [
    "sent",
    "in_progress",
    "interview",
    "test",
    "hr",
    "waiting_user",
    "accepted",
    "rejected",
]