
const isApplied = (id) => {

    const myApplications = window.localStorage.getItem("applications");
    if(myApplications && JSON.parse(myApplications).includes(id)) {
        return true;
    } else {
        return false;
    }
}

export { isApplied }