import _ from "lodash";
import {URLS} from "../../services/urls"

let ls = {}

ls.set = window.localStorage.setItem.bind(localStorage);
ls.get = window.localStorage.getItem.bind(localStorage);

const getUserDetails = () => JSON.parse( window.localStorage.getItem("user-details"));
const setUserDetails = (details) => window.localStorage.setItem("user-details", JSON.stringify(details));

const appScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
}

const formatLinkString = (link) => {
  if(link?.includes("https://")){
    return link;
  } else {
    return URLS.BASE_URL + link;
  }
}

const validatePhoneNumber = (
  inputNumber,
  country,
  isDirty,
  phoneLength
) => {
  if (isDirty) {
    if (
      inputNumber &&
      inputNumber?.replace(country.dialCode, '')?.trim() === ''
    ) {
      return false;
    } else if (inputNumber.length < phoneLength) {
      return false;
    }
    return true;
  }
  return false;
};

const getQuantity = (category, searchResults, defaultValue, canEvaluate) => {

  if(canEvaluate){

    return searchResults.filter((item) => item.industry.id === category.id).length

  } else {

    return defaultValue;
  }

}




export {ls, setUserDetails, getUserDetails, appScrollTo, formatLinkString, validatePhoneNumber, getQuantity}