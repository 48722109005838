
export const styles = {
    input: (baseStyles, state) => ({
      ...baseStyles,
      height: "40px",
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      height: state.isFocused ? "15px" : '12px',
      display: 'none'
    })
  }