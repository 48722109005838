import useSWR from "swr";
import { URLS } from "../../services/urls";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { loginSuccess } from "../../store/slices/auth";
import { getUserDetails, ls, setUserDetails } from "../utils/functions";
import { API } from "../../services/axios";



const Auth = (props) => {
    
    const userDetails = getUserDetails();
    const dispatch = useDispatch();

    useEffect(() => {
        if(userDetails){
            API.get(URLS.AUTHS.me).then((resp) => {
                dispatch(loginSuccess({...userDetails, user: resp.data}))
                setUserDetails({...userDetails, user: resp.data})
            }).catch((error) => {
                dispatch(loginSuccess({...userDetails}))
            })
        }
    }, );

    return <div></div>;
}

export default Auth