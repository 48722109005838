import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatLinkString } from "../../../../../components/utils/functions";
import { APPLICATION_STEPS } from "../../../../../components/utils/consts";

const ApplicationCard = ({ application }) => {
  const {t} = useTranslation()
  return (
    <div className="col-xl-6 col-lg-6 col-md-6">
      <Link to={`/dashboard/my-applications/${application?.id}/details`} className="card-style-2 hover-up">
        <div className="card-head">
          <div className="card-image">
            {" "}
            <img src={application?.offer ? formatLinkString(application?.offer?.image) : "/assets/imgs/page/dashboard/img1.png"} alt={application?.offer?.title} />
          </div>
          <div className="card-title">
            <h6>{application?.offer?.title}</h6>
            <span className="text-muted">{t("textAppliedOn")}: </span>
            <span>{moment(application?.created_at).fromNow()}</span>
          </div>
        </div> 
        <div className="mr-15">
          {" "}
          {/* <a className={`btn btn-tag ${application.status === 'cancelled' ? 'high' : application.status === 'validated' ? 'complete' : 'planing'}`}>{application.status}</a> */}
          {
            APPLICATION_STEPS.map(function(step, index) {
              
                return <span key={step + index} className={`btn btn-tags-sm mb-10 mr-5 ${step === application?.status ? 'actual-step' : index < APPLICATION_STEPS.indexOf(application?.status) ? 'step-passed' : 'next-step'}`}>
                  {index < APPLICATION_STEPS.indexOf(application?.status) && <span><img src="/assets/imgs/page/homepage3/check-box.svg" width={"10px"} className="mr-5" /></span>}
                  {index+1}: {t(`text_${step}`)}</span>
            })
            }
            {
            // t(`text_${application?.status}`)

          }
        </div>
        {/* <div className="card-progress">
          <span>Complete: </span>
          <strong>80</strong>
          <span className="hour"> %</span>
        </div> */}
      </Link>
    </div>
  );
};

export default ApplicationCard;
