import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatLinkString } from "../../../../../components/utils/functions";

const LanguageCard = (props) => {
  const { language, handleChange, handleDelete } = props;

  const { t } = useTranslation();
  return (
    <div className="item-timeline">
      <div className="timeline-year">
        {" "}
        <span>
          {t("textLevel")}: {" "} {language?.level}
        </span>
      </div>
      <div className="timeline-info">
        <h5 className="color-brand-1 mb-20">{language?.designation}</h5>
        <p className="color-text-paragraph-2 mb-15">
          {t("textDescription")}: <span dangerouslySetInnerHTML={{__html: language?.description}}></span> <br/>
        </p>
      </div>
      <div className="timeline-actions">
        {" "}
        <Link to={"#"} onClick={(e) => handleChange(language)} className="btn btn-editor position-inherit m-auto"></Link>
        <Link to="#" onClick={(e) => handleDelete(language)} className="btn btn-remove m-auto"></Link>
      </div>
    </div>
  );
};

export default LanguageCard;
